import car from './images/car.png';
import gym from './images/gym.png';
import pool from './images/pool.png';
import laundry from './images/laundry.png';
import clubroom from './images/clubroom.png';
import mailroom from './images/mailroom.png';
import elevator from './images/elevator.png';
import door from './images/door.png';

// ids match values in access_point_type table in server
export const GATE_TYPES = {
    CAR: { id: 1, icon: car },
    GYM: { id: 2, icon: gym },
    POOL: { id: 3, icon: pool },
    LAUNDRY: { id: 4, icon: laundry },
    CLUBROOM: { id: 5, icon: clubroom },
    MAILROOM: { id: 6, icon: mailroom },
    ELEVATOR: { id: 7, icon: elevator },
    DOOR: { id: 8, icon: door }
}


export const DEFAULT_GATE_ICON = 'DOOR';

export const GATE_STATUS = {
    LOCKED: 'LOCKED',
    UNLOCKED: 'UNLOCKED',
    IN_PROGRESS: 'IN_PROGRESS',
    ERROR: 'ERROR',
  };
  

  export const BUTTON_FADE_TIMEOUT = 1500;
