import { useEffect, useState } from "react";

const AppLayout = ({renderContent}) => {

    const [winWidth, setWinWidth] = useState(window.innerWidth);

    const isMobile = winWidth <= 768;

    // console.log({winWidth, isMobile});

    useEffect(() => {
        const handleWindowResize = () => {
          setWinWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        }
    })

    return isMobile ? (
        <div className="app-container">
            <div className="app-container-single-column">
                {renderContent(isMobile)}
            </div>
        </div>
    ) : (
        <div className="app-container">
            <div className="app-container-side-column" />
            <div className="app-container-center-column">
                {renderContent(isMobile)}
            </div>
            <div className="app-container-side-column" />
        </div>
    )
}

export default AppLayout;