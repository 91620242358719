import wide_button from './images/wide_button.png';
import wide_button_pressed from './images/wide_button_pressed.png';
import wide_button_badge from './images/wide_button_badge.png';
import wide_button_pressed_badge from './images/wide_button_presssed_badge.png';
import opening_gate from './images/opening_gate.gif';
import lock from './images/lock.png';
import unlock from './images/unlock.png';
import { DEFAULT_GATE_ICON, GATE_STATUS, GATE_TYPES } from './constants';

const gateIcon = (type, status) => {

    if (status === GATE_STATUS.UNLOCKED) {
        return unlock;
    }
    if (status === GATE_STATUS.ERROR) {
        return lock;
    }
    if (status === GATE_STATUS.IN_PROGRESS) {
        return opening_gate;
    }

    const gate = Object.values(GATE_TYPES).find(({id}) => id === type);
    
    return (gate || GATE_STATUS[DEFAULT_GATE_ICON]).icon;
}

const ButtonBadge = ({isPressed, typeIcon, isMobile}) => {

    const badgeIcon = isPressed ? wide_button_pressed_badge : wide_button_badge;

    const responsiveStyle = isMobile ? {
        marginLeft: '7%',
        marginRight: '2%',
    } : {
        marginLeft: '35px',
        marginRight: '10px'
    }

    const style = {
        backgroundImage: `url(${badgeIcon})`,
        ...responsiveStyle,
    }

    return (
        <div className="wide-button-badge" style={style}>
            <img src={typeIcon} className="wide-button-icon" alt="" />
        </div>
    )
}

const ButtonInfo = ({name}) => (
    <div className="wide-button-info">
        <span className="button-name">{name}</span>
    </div>
)

const WideButton = ({onPress, gate: { access_point_type_id: type, id, name }, status, isMobile}) => {
    
    const onClick = () => onPress(id);

    const isPressed = status === GATE_STATUS.IN_PROGRESS;
    const buttonIcon = isPressed ? wide_button_pressed : wide_button;
    const typeIcon = gateIcon(type, status);

    const responsiveStyle = isMobile ? {
        width: '300px',
        height: '80px'
    } : {
        width: '400px',
        height: '100px'
    }

    const style = {
        backgroundImage: `url(${buttonIcon})`,
        paddingBottom: isPressed ? 2 : 10,
        ...responsiveStyle
    }

    return (
        <div className="wide-button" style={style} onClick={onClick}>
            <ButtonBadge isPressed={isPressed} typeIcon={typeIcon} isMobile={isMobile}/>
            <ButtonInfo name={name}/>
        </div>
    )
}

export default WideButton;
