import circular_button from './images/circular_button.png';
import circular_button_pressed from './images/circular_button_pressed.png';
import opening_gate from './images/opening_gate.gif';
import lock from './images/lock.png';
import unlock from './images/unlock.png';
import { DEFAULT_GATE_ICON, GATE_STATUS, GATE_TYPES } from './constants';

const ButtonInfo = ({name}) => (
    <div className="circular-button-info">
        <span className="button-name">{name}</span>
    </div>
)

const gateIcon = (type, status) => {

    if (status === GATE_STATUS.UNLOCKED) {
        return unlock;
    }
    if (status === GATE_STATUS.ERROR) {
        return lock;
    }
    if (status === GATE_STATUS.IN_PROGRESS) {
        return opening_gate;
    }

    const gate = Object.values(GATE_TYPES).find(({id}) => id === type);
    
    return (gate || GATE_STATUS[DEFAULT_GATE_ICON]).icon;
}

const CircularButton = ({onPress, gate: { access_point_type_id: type, id,name }, status, singleButton}) => {
    
    const onClick = () => onPress(id);

    const isPressed = status === GATE_STATUS.IN_PROGRESS;
    const buttonIcon = isPressed ? circular_button_pressed : circular_button;    
    const iconSize = singleButton ? 100 : 90;

    const typeIcon = gateIcon(type, status);

    const buttonStyle = {
        backgroundImage: `url(${buttonIcon})`, 
        paddingBottom: isPressed ? 2 : 10,
        height : singleButton ? 200 : 170
    }

    const iconStyle = {
        width: iconSize,
        height: iconSize,
    }

    return (
        <div className="circular-button-wrapper">
            <div className="circular-button" style={buttonStyle} onClick={onClick}>
                <img src={typeIcon} className="circular-button-icon" style={iconStyle} alt="" />
            </div>
            <ButtonInfo name={name}/>
        </div>
    )
}

export default CircularButton;
