import './App.css';
import { useCallback, useEffect, useState } from 'react';
import { BUTTON_FADE_TIMEOUT, GATE_STATUS } from './constants';
import AppLayout from './AppLayout';
import WideButton from './WideButton';
import CircularButton from './CircularButton';

const STAGING_HOST = 'https://gatewise-staging.herokuapp.com';
const PRODUCTION_HOST = 'https://portal.gatewise.com';

const host = process.env.REACT_APP_ENV === 'staging' ? STAGING_HOST : PRODUCTION_HOST;
// const host = 'http://localhost:3000';
const token = window.location.href.split('/').slice(-1).pop();

const Error = ({error}) => (
  <div className="error-container">
    <p className="error">{error}</p>
  </div>
)

const AppContent = ({gates, gatesStatus, openGate, isMobile}) => {

  const wideButtons = gates.length > 2;
  const Button = wideButtons ? WideButton : CircularButton;

  return (
    <div className="buttons" style={{justifyContent: wideButtons ? 'flex-start' : 'center'}}>
      {gates.map((gate) => (
        <Button key={gate.id} gate={gate} status={gatesStatus[gate.id]} onPress={openGate} isMobile={isMobile} singleButton={gates.length === 1}/>
      ))}
    </div>  
  )
}

const App = () => {

  const [gates, setGates] = useState([]);
  const [gatesStatus, setGatesStatus] = useState({});
  const [fetchGatesError, setFetchGatesError] = useState()
  
  const setSingleGateStatus = (id, status) => {
    setGatesStatus(prevState => ({
      ...prevState,
      [id]: status,
    })); 
  }

  const gatesInitialStatus = data => (
    data.reduce((status, {id}) => ({
      ...status,
      [id]: GATE_STATUS.LOCKED
    }), {})    
  )

  const showError = error => {
    setTimeout(() => {
      alert(error)
    }, 100);
  };

  const fetchGates = useCallback(() => {
    if (!token) {
      setFetchGatesError('Error: missing token in url');
      return;
    }
    fetch(`${host}/api/v1/visitor/${token}/gates`)
      .then(res => res.json())
      .then(result => {
        const {status, data} = result;
        // console.log({result});
        if (status === 'ok') {
          if (data.length > 0) {
            setGates(data);
            setGatesStatus(gatesInitialStatus(data));  
          } else {
            setFetchGatesError('There are no available gates');
          }
        } else {
          console.log({status, data});
          setFetchGatesError('There are no available Gates');
        }
      }, error => {
        console.log({error});
        setFetchGatesError('There are no available Gates');
    })
  }, [])

  const delayedResetGateStatus = id => {
    setTimeout(() => {
      setSingleGateStatus(id, GATE_STATUS.LOCKED); 
    }, BUTTON_FADE_TIMEOUT);    
  }

  const openGate = (id) => {
    
    setSingleGateStatus(id,GATE_STATUS.IN_PROGRESS);
    
    fetch(`${host}/api/v1/visitor/open_gate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token,
        access_point_id: id
      })
    })
      .then(res => res.json())
      .then(result => {
        const {status} = result;
        // console.log({result});
        setSingleGateStatus(id, status === 'ok' ? GATE_STATUS.UNLOCKED : GATE_STATUS.ERROR);
        delayedResetGateStatus(id);
        if (status !== 'ok') {
          const {error = 'unknown'} = result;
          showError(error);
        }
      }, error => {
        console.log({error});
        setSingleGateStatus(id, GATE_STATUS.ERROR); 
        delayedResetGateStatus(id);
        showError(error);
    })
  }

  useEffect(() => {
    fetchGates();
  }, [fetchGates]);

  return (
    <div className="app">
      <div className="header" >
        <span className="header-title">Community Gates</span>
        <span className="header-subtitle">Visitor Key</span>
      </div>
      {
        fetchGatesError ? (
          <Error error={fetchGatesError} />
        ) : (
          <AppLayout renderContent={(isMobile) => (
            <AppContent 
              gates={gates} 
              gatesStatus={gatesStatus} 
              openGate={openGate} 
              isMobile={isMobile}
            />
        )}>
        </AppLayout>  
        )
      }
    </div>
  );
}

export default App;
